<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-9">
          <div class="fd-cadastro-form-header">
            <h1>Cadastre-se hoje mesmo!</h1>
          </div>
          <div class="fd-cadastro-form">
            <form
              id="formcadastro"
              role="form"
            >
              <div class="messages" />
              <div class="form-group">
                <label for="nome_completo">* Seu nome completo</label>
                <input
                  id="nome_completo"
                  type="text"
                  class="form-control form-control-lg"
                  aria-describedby="nome_completoinfo"
                  placeholder="Como devemos chama-lo(a)?"
                  required="required"
                  data-error="Digite seu nome"
                >
                <small
                  id="nome_completoinfo"
                  class="form-text text-muted"
                >Apelido é aceito, mas recomendamos que utilize seu nome.</small>
                <div
                  class="help-block with-errors"
                  style="color:red"
                />
              </div>
              <div class="form-group">
                <label for="email">* Seu email de trabalho</label>
                <input
                  id="email"
                  type="email"
                  class="form-control form-control-lg"
                  aria-describedby="emailinfo"
                  placeholder="Como poderemos falar com você?"
                  required="required"
                  data-error="Digite um e-mail válido"
                >
                <small
                  id="emailinfo"
                  class="form-text text-muted"
                >Este e-mail que será utilizado para realizar login.</small>
                <div
                  class="help-block with-errors"
                  style="color:red"
                />
              </div>
              <div class="form-group">
                <label for="senha">* Sua senha para acesso</label>
                <input
                  id="senha"
                  type="password"
                  class="form-control form-control-lg"
                  aria-describedby="senhainfo"
                  placeholder="Sua senha"
                  required="required"
                  data-error="Digite uma senha"
                >
                <small
                  id="senhainfo"
                  class="form-text text-muted"
                >Utilize uma senha de pelo menos 8 digitos.</small>
                <div
                  class="help-block with-errors"
                  style="color:red"
                />
              </div>
              <div class="mt-5 text-center">
                <p class="text-muted">
                  Ao criar uma conta, você declara que leu e aceita os
                  <a href="#">Termos de Uso</a>.
                </p>
                <input
                  id="Salvar"
                  type="submit"
                  class="btn btn-primary btn-lg"
                  value="Estou Pronto para começar!"
                >
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="fd-cadastro-form-footer">
        <div class="mt-3 text-center">
          <img src="public/images/footer-logo.png">
          <small class="text-muted">Todos os direitos reservados.</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "Cadastro",
  mixins: [methods],
  data: function() {
    return {
      nomeUsuario: this.retornaSessao(settings.fastSessaoUsuario).First_name,
    };
  },
  mounted: function() {
    // Informa ao component pai para interromper o loading
    this.$store.state.fastCarregando = false;
  },
};
</script>

<style></style>
